var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-row ",style:({ margin: _vm.margin })},[_c('div',{staticClass:"m-auto ",style:({ 'max-width': _vm.maxWidth + 'px' })},[_c('div',{key:_vm.row.id,style:({display:'grid', 'grid-template-columns': ("repeat(" + (_vm.columns.length) + ", minmax(0, 1fr))"), gap: '4px'})},_vm._l((_vm.row.columns),function(column){return _c('div',{key:column.id},[(column.type=='OPTION')?_c('div',{key:_vm.step.id,staticClass:"flex flex-wrap mx-auto",class:[
            _vm.alignClass(column),
            _vm.justifyClass(column),
            _vm.flexClass(column) ],style:({ 'max-width': '910px' }),attrs:{"id":"option_list"}},_vm._l((_vm.options),function(option){return _c('text-option',{key:option.id,attrs:{"option":option,"survey":_vm.survey,"step":_vm.step,"selected":option.selected},on:{"click":function (option) { return _vm.$emit('click-option', option); }}})}),1):_c('div',{staticClass:"flex h-full",class:[
            _vm.alignClass(column),
            _vm.justifyClass(column),
            _vm.flexClass(column) ]},_vm._l((column.contents),function(content){return _c('div',{key:content.id,staticClass:"content-div p-px flex-initial",class:{
              'w-full': content.type == 'FIELD' || content.type == 'CODE' ,
            }},[(column.contents.length > 0)?_c(content.type + 'Content',{tag:"component",style:({
                'margin-top': content.marginTop + 'px',
                'margin-bottom': content.marginBottom + 'px',
              }),attrs:{"content_id":content.id,"survey":_vm.survey,"step":_vm.step},on:{"click":function (model) {
                  _vm.$emit('click', model);
                }}}):_vm._e()],1)}),0)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }