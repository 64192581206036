<template>
  <div id="appflowz-wrapper">
    <widget-main
      v-for="survey in surveys"
      :key="survey.id"
      :survey_id="survey.id"
      :stepid="stepid"
      @close-widget="closeWidget"
      @router-push="routerPush"
    />
  </div>
</template>

<script>
/* This is a wrapper for our main Widget display. The web component build not process main.js so any Vue
settings or setup required for our widget should go here. */

/* Web Component Resources

https://vuejsdevelopers.com/2018/05/21/vue-js-web-component/
https://github.com/vuejs/vue-web-component-wrapper
https://github.com/karol-f/vue-custom-element
https://github.com/DJanoskova/Vuidget/tree/master/src

*/

import axios from "axios";
import WidgetMain from "@/WidgetMain";
import store from "@/store/store.js";
import router from "@/router/widget-router.js";
//import "./widget.css";
import Survey from "@/models/Survey";

axios.defaults.withCredentials = true;
//Axios Base Url for backend server
axios.defaults.baseURL =
  process.env.VUE_APP_LARAVEL_URL || "https://api.appflowz.com";

export default {
  store,
  router,
  components: { WidgetMain },
  props: {
    /*
    survey_id: {
      type: String,
      required: true,
    },
  */
    appflowz_config: {
      type: String,
      required: true,
    },
    /*
    account: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    user_id: {
      type: String,
      required: false,
    },
    */
  },
  //props: ["survey_id", "email", "name", "user_id"],
  data() {
    return {
      //showWidget: false,
      //survey_id: null,
      surveys: [],
    };
  },
  computed: {
    /*
    survey() {
      var survey = Survey.query()
        .whereId(this.survey_id)
        .with("steps", (query) => {
          query
            .orderBy("order", "asc")
            .with("options", (query) => {
              query.orderBy("order", "asc").with("contents", (query) => {
                query.orderBy("order", "asc");
              });
            })
            .with("rows", (query) => {
              query.orderBy("order", "asc").with("columns", (query) => {
                query.orderBy("order", "asc").with("contents", (query) => {
                  query.orderBy("order", "asc");
                });
              });
            });
        })
        .first();
      return survey || {};
    },
    */

    stepid() {
      return this.$route.params.id || null;
    },
    url() {
      return window.location;
    },
    path() {
      return this.url.pathname;
    },
    domain() {
      return this.url.hostname;
    },
    account(){
      return this.$store.state.configVars.account;
    },
    email(){
      return this.$store.state.configVars.email;
    },
  },
  methods: {
    async closeWidget(survey) {


      //trigger webhook
      let visitor = this.$store.state.visitor;

      try {
        await Survey.api().get(`/api/webhook/call/survey/${this.survey.id}/visitor/${visitor.id}`);
      } catch (err) {
        console.log(err);
        //TO DO: Handle errors
      }

      if (survey === undefined || survey == null) {
        this.surveys = [];
      }

      //reset path back to step 0 and close
      router.push({
        name: "show",
        //params: { id: null },
      });

      let index = this.surveys.findIndex((item) => item.id === survey.id); //lookup survey by survey.id

      this.surveys.splice(index, 1);

      if (this.surveys.length < 1){
        const html = document.documentElement;
        html.classList.remove("appflowz-reset");
      }

    },
    displayWidget(survey) {

      const html = document.documentElement;
      html.classList.add("appflowz-reset");

      this.surveys.push(survey);
      window.sessionStorage.setItem(survey.id, "shown");
    },
    routerPush(obj) {
      router.push(obj);
    },
    async urlMatch() {
      //Check if the flow is set to be displayed on the current domain/path

      console.log('APPFLOWZ_WIDGET: ' + this.account);

      //key: current url, value: survey_id
      let surveys = null;
      let key = this.domain + this.path;

      /** Storing the survey in sessionstorage makes it so that we don't need to hit the server on every page load.
        * But this creates issues for users getting set up because it wont immediately update when a user
        * changes the survey display settings.
      * */

      //let storedSurvey = window.sessionStorage.getItem(key);
      let storedSurvey = null;

      if (storedSurvey == null) {
        let response = await axios.post(`/api/survey/urlmatch`, {
          account_id: this.account,
          domain: this.domain,
          path: this.path,
        });

        surveys = response.data.result;

        //console.dir(survey.id);
        //window.sessionStorage.setItem(key, JSON.stringify(surveys));
      } else {
        surveys = JSON.parse(storedSurvey);
      }

      return surveys;
    },
  },
  async mounted() {

    this.$store.commit('SET_CONFIG_VARS', JSON.parse(this.appflowz_config));
    //this.$store.commit('SET_VISITOR', this.visitor);

    let surveys = await this.urlMatch();

    //console.log("length: " + surveys.length);

    if (surveys.length > 0) {
      //console.log("survey id: " + survey.id);

      //if (survey.published) {
      //console.log("published");
      for (const survey of surveys) {
        if (survey.displayTrigger == "ALWAYS") {
          //console.log("ALWAYS");

          this.displayWidget(survey);
        } else if (survey.displayTrigger == "UNTIL_RESPOND") {
          //console.log("UNTIL_RESPOND");

          //check if we've already shown the survey once in the current session
          const shown = window.sessionStorage.getItem(survey.id);

          //console.log("shown value: " + shown);

          if (shown != "shown") {
            let response = await axios.get(
              `/api/survey/email/${this.email}/survey/${survey.id}`
            );

            let result = response.data.result;
            //console.log("result is: " + result);

            if (result == "1") {
              this.displayWidget(survey);
            } else if (result == "4") {
              //save responder in session storage
            }
          }
        }
      }
    }
  },
};
</script>

<style>
@import "./assets/widget.css";
@import "./assets/editor.scss";
#appflowz-wrapper {
  font-size: 16px;
}
</style>
