import { Content } from './ContentHierarchy' 

export class CodeField extends Content {
  // entity is a required property for all models.
  static entity = "code_field";

  // Reference to base entity name.
  static baseEntity = "content";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields(),
      text: this.string("Paste your code here..."),
      size: this.attr("md"), //'sm', 'md', 'lg'
      width: this.attr(150),
      bgColor: this.attr(null),

    };
  }
}

export default CodeField;
