<template>
  <div v-if="model.field_type == null">Input type not set.</div>
  <div v-else>
    <BaseInlineErrors
      :errors="errors.field"
      :style="{ 'font-family': survey.fontFamily }"
    />
    <input
      class="flex-1 appearance-none 
       w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base border focus:border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 "
      :type="model.field_type"
      :placeholder="placeholder"
      :name="model.name"
      v-model="field"
      @blur="validate"
      :style="{
        'font-family': survey.fontFamily,
        'border-color': borderColor,
        'background-color': bgColor,
        'border-radius': borderRadius + 'px',
        'font-size': fontSize + 'px',
        padding: paddingY + 'px ' + paddingX + 'px',
      }"
    />
  </div>
</template>

<script>
import FormField from "@/models/content/FormField";
import FormAnswer from "@/models/FormAnswer";
import Visitor from "@/models/Visitor";
import BaseInlineErrors from "@/components/BaseInlineErrors";
import { FormValidator } from "@/mixins/FormValidator.js";
import EventBus from "@/event-bus.js";

export default {
  name: "form-field",
  mixins: [FormValidator],
  components: {
    BaseInlineErrors
  },
  data() {
    return {
      errors: [],
      validated: false
    };
  },
  /*
  validations: {
    field: {
      required: true,
      isEmail: true,
      maxLength: 255,
    },
  },
  */
  props: ["content_id", "survey", "step"],
  computed: {
    cssVars() {
      return {
        "--text-color": this.color,
        "--font-size": this.fontSize + "px",
        "--font-family": this.survey.fontFamily,
      };
    },

    model() {
      return FormField.find(this.content_id);
    },

    formAnswer() {
      if (this.visitor != null) {
        return FormAnswer.query()
          .where("field_id", this.model.id)
          .where("visitor_id", this.visitor.id)
          .first();
      } else return null;
    },

    field: {
      get() {
        if (this.formAnswer != null) return this.formAnswer.value;
        else return null;
      },
      set(value) {

        FormAnswer.update({
          where: this.formAnswer.id,
          data: {
            value: value,
          },
        });

        //update our FieldVars object so that users can access the form value via {{ name }}
        let payload = {};
        payload.key = this.model.name;
        payload.value = value;
        this.$store.commit("UPDATE_FIELD_VARS", payload);
      },
    },

    visitor() {
      return this.$store.state.visitor;
    },

    placeholder() {
      //default placeholder text
      let ph =
        this.model.field_type == "email"
          ? "Enter your email"
          : this.model.field_type == "url"
          ? "Enter website url"
          : "Enter your text";

      return this.model.text ?? ph;
    },

    color() {
      return this.model.fontColor;
    },

    fontSize() {
      return this.model.fontSize;
    },

    bgColor() {
      return this.model.bgColor;
    },

    borderColor() {
      return this.model.borderColor;
    },

    borderRadius() {
      return this.model.borderRadius;
    },

    paddingX() {
      return this.model.paddingX;
    },

    paddingY() {
      return this.model.paddingY;
    },
  },
  methods: {
    async submit(){
      //console.log('in submit method')
      if (this.validate){
        let answer = this.formAnswer;
        await FormAnswer.api().post("/api/response/save", answer);
      }
    },
    async validate() {
      //get errors array from Step model
      //let stepErrors = this.step.errors;

      //get any previously saved errors for this field
      let index = this.step.errors.findIndex((obj) => obj.id == this.model.id);

      let validations = {
        field: {
          maxLength: 255,
        },
      };

      //set validations for this field
      if (this.model.required) validations.field["required"] = true;
      if (this.model.field_type == "email") validations.field["isEmail"] = true;
      if (this.model.field_type == "url") validations.field["isUrl"] = true;

      if (await this.validateForm(validations)) {

        //valid, remove any previously saved errors for this field
        if (index > -1) {
          this.step.errors.splice(index, 1);
        }

        return true;
     
      //not valid, return errors
      } else {
        let errorObj = { id: this.model.id, messages: this.errors.field };

        console.dir(errorObj);

        //if no previous errors for this field, add new error
        if (index == -1) {
          //stepErrors.push(errorObj);
          this.step.errors.splice(this.step.errors.length, 0, errorObj);
        } else {
          //if previously saved error, replace/update
          this.step.errors.splice(index, 1, errorObj);
        }
        return false;
      }
 
    },
    required() {
      return true; // (this.model != undefined) ? this.model.required : false;
    },
    async init() {
      await FormAnswer.insertNew({
        data: {
          field_id: this.model.id,
          visitor_id: this.visitor.id,
          survey_id: this.survey.id,
          step_id: this.step.id,
        },
      });
    },
  },

  watch: {
    async visitor() {
      if (this.formAnswer === null) {
        this.init();
      }
    },
  },

  async mounted() {
    if (this.visitor != null) {
      if (this.formAnswer === null) {
        this.init();
      }
    }

    EventBus.$on('submit', this.submit);

  }, 
};
</script>

<style></style>
