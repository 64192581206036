import ActiveRecord from "@/models/ActiveRecord";

export default class Integration extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "integration";


  //foreign key of the parent entity
  static foreignKey = "campaign_id";

  /*
  static types () {
    return {
      WEBHOOK: Webhook,
    }
  */

  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      user_id: this.attr(null),
      name: this.attr(null),
      endpoint: this.attr(null), //webhook endpoint url or api endpoint url
      campaign_id: this.attr(null),
      type: this.attr('WEBHOOK'),
      secret: this.attr(null) //webhook secret or api key/token
    };
  }
}
