import ActiveRecord from "@/models/ActiveRecord";
import Column from "@/models/content/Column";

export default class Row extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "row";

  //foreign key of the parent entity
  static foreignKey = "step_id";

  /*
  static types () {
    return {
      TEXT: Text,
      IMAGE: Image,
      COLUMN: Column,
    }
  }
*/

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      parent_id: this.attr(null),
      parent_type: this.attr(null),
      //parent: this.morphTo('parent_id', 'parent_type'),
      //step_id: this.attr(null),
      type: this.attr('COLUMN'), //what the row contains, e.g. 'IMAGE' (option), 'COLUMN'
      order: this.number(99999),
      marginTop: this.number(5),
      marginBottom: this.number(5),
      maxWidth: this.number(900),
      bgColor: this.attr(null),
      columns: this.hasMany(Column, "row_id"),
      
      /*
      align: this.string("center"), //right, left, center, justified
      width: this.number(100),
      height: this.number(166),
      x: this.number(0),
      y: this.number(50),
      paddingLeft: this.number(10),
      paddingRight: this.number(10),
      contents: this.hasMany(Content, "row_id"),
      */

    };
  }

  $clone() {
    return Row.clone(this);
  }

  $replaceIds() {
    return Row.replaceIds(this);
  }

  static clone(orig) {
    let clone = { ...orig }; //Make a deep copy of step object including child options
    clone = this.replaceIds(clone);
    return clone;
  }

  //Recursively replacing primary ids and child foreign keys 
  static replaceIds(row) {
    row.id = this.uuidv4(); //Create new primary id

    row.columns.forEach( (column) => {
      column.row_id = row.id;
      column.$replaceIds();

    });

    return row;
  }

  static afterCreate(model) {
    this.reorder(model);
  }

  static afterDelete(model) {
    this.reorder(model);
  }
}
