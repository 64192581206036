<template>
  <div>
    <div
      class="code-wrap rounded-lg relative mx-auto p-4"
      style="background-color: #272822"
    >
      <div class="mx-auto w-min" v-html="clean_text"></div>
    </div>
  </div>
</template>

<script>
import Video from "@/models/content/Video";
//import SvgIcon from '../SvgIcon.vue';

export default {
  components: {},

  data() {
    return {};
  },

  props: ["content_id", "step", "survey"],

  computed: {
    model() {
      return Video.find(this.content_id);
    },

    clean_text() {
      return this.model.clean_text;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style>

</style>
