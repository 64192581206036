<template>
  <div>
    <!-- The actual button starts here -->
    <div
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="$emit('click', model)"
      class="content-button hover:no-underline cursor-pointer inline-block border border-solid border-transparent"
      :class="{ 'hover-active': hover, 'no-hover': !hover }"
      :style="{
        color: model.fontColor,
        'font-family': survey.fontFamily,
        'background-color': bgColor,
        'border-color': borderColor,
        'border-radius': borderRadius + 'px',
        padding: paddingY + 'px ' + paddingX + 'px',
      }"
    >
      <div v-html="text" class="ProseMirror" :style="cssVars"></div>
    </div>
  </div>
</template>

<script>
import Button from "@/models/content/Button";

export default {
  name: "button-content",
  components: {},
  data() {
    return {
      hover: false,
    };
  },
  props: ["content_id", "survey", "step", "align"],
  computed: {

    text() {
      return this.model.text;
    },

    cssVars() {
      return {
        "--text-color": this.color,
        "--font-size": this.fontSize + "px",
        "--font-family": this.survey.fontFamily,
      };
    },

    model() {
      return Button.find(this.content_id);
    },

    color() {
      return this.model.fontColor ?? this.survey.buttonFontColor;
    },
    fontSize() {
      return this.model.fontSize;
    },
    bgColor() {
      return this.model.bgColor ?? this.survey.buttonBgColor;
    },
    borderColor() {
      return this.model.borderColor ?? this.survey.buttonBorderColor;
    },
    borderRadius() {
      return this.model.borderRadius;
    },
    paddingX() {
      return this.model.paddingX;
    },
    paddingY() {
      return this.model.paddingY;
    },
    goto() {
      return this.model.goto;
    },
    gotoLink() {
      return this.model.goto_link;
    },
  },
  methods: {},

  watch: {},

  mounted() {},
};
</script>

<style></style>
