import { Content } from "./ContentHierarchy";
//import Content from "@/models/content/ContentHierarchy";

export class Emoji extends Content {
  // entity is a required property for all models.
  static entity = "text";

  // Reference to base entity name.
  static baseEntity = "content";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      ...super.fields(),
      text: this.string("This is demo text"),
      fontColor: this.string("#2c3e50"),
      fontSize: this.number(20),
    };
  }
}

export default Emoji;
