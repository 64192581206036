//import Content from "@/models/content/ContentHierarchy";
import { Content } from './ContentHierarchy' 
import FormAnswer from "@/models/FormAnswer";

export class FormField extends Content {
  // entity is a required property for all models.
  static entity = "form_field";

  // Reference to base entity name.
  static baseEntity = 'content' 

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      ...super.fields(),
      text: this.attr(null), //placeholder text

      field_type: this.attr(null), //"email", "url", "text"
      required: this.boolean(false),
      name: this.attr(null),

      fontColor: this.string("#333333"),
      fontSize: this.number(16),
      bgColor: this.string("#FFFFFF"), 
      borderColor: this.string("#d1d5db"),
      borderRadius: this.number(5),
      paddingX: this.number(20),
      paddingY: this.number(8),
      marginTop: this.number(2),
      marginBottom: this.number(8),
      //answerValue: this.attr(null) //value entered by visitor
      answer: this.hasMany(FormAnswer, 'field_id')
      
    };
  }

  static dummyData(fk_val) {

    return {
      row_id: fk_val,
      text: "Button",
    };
  }

}

export default FormField;
