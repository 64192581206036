import { Content } from "./ContentHierarchy";
//import Content from "@/models/content/ContentHierarchy";

export class Text extends Content {
  // entity is a required property for all models.
  static entity = "editor";

  // Reference to base entity name.
  static baseEntity = "content";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      ...super.fields(),
      text: this.string("This is demo text"),
      clean_text: this.string("This is demo text"),
      fontColor: this.attr(null),
      fontSize: this.number(20),
      //textAlign: this.attr("center")
    };
  }

  static dummyData(foreign_id) {

    return {
      row_id: foreign_id,
      text: "Default text",
    };
  }

}

export default Text;
