import ActiveRecord from "@/models/ActiveRecord";

export default class Visitor extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "visitor";

  //NOTE: 'user_id' in the pixel == 'customer_id' in our Visitor model below 
  //'account' in the pixel == 'user_id' below (foreign key for the User who own the visitor )

  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      user_id: this.attr(null),
      email: this.attr(null),
      name: this.attr(null),
      customer_id: this.attr(null) //optional field that allows user to provide their own customer id value
    };
  }
}
