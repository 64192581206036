<template>
  <div class="relative">
    <transition name="copy-button-fade">
    <div v-show="copied" class="absolute -top-10 right-5 bg-gray-600 text-white p-2 text-sm rounded cursor-pointer hover:opacity-75" @click="copied=false">copied!</div>
    </transition>
    <div
      class="code-wrap rounded-lg relative mx-auto p-4"
      style="background-color: #272822"
    >
      <pre><code
class="language-html input"
:style="{ height: calcHeight + 80 + 'px'}">{{text}}
</code></pre>

      <div class="absolute right-4 top-2">
        <div
          class="copy-button bg-green-500 text-white rounded flex flex-row items-center justify-center cursor-pointer py-1 px-2 border-b border-r border-solid border-green-200"
          @click="copyCode"
        >
          <svg-icon icon="heroicons/solid/clipboard-check" class="h-6 w-6" />

          Copy to clipboard
        </div>
      </div>
    </div>
    <textarea
      ref="code_text"
      :value="text"
      style="max-height:1px;max-width:1px;oveflow:none;"
      class="focus:outline-none"
    ></textarea>
  </div>
</template>

<script>
import Prism from "prism-es6";
import "prism-es6/components/prism-markup";
//import "prism-es6/themes/prism-okaidia.css";
import Code from "@/models/content/CodeField";
import SvgIcon from "../SvgIcon.vue";

export default {
  components: { SvgIcon },

  data() {
    return {
      bubbleMenu: null,
      copied: false,
    };
  },

  props: ["content_id", "step", "survey"],

  computed: {
    cssVars() {
      return {
        "--font-size": this.fontSize + "px",
        "--font-family": this.survey.fontFamily,
      };
    },

    model() {
      return Code.find(this.content_id);
    },

    text() {
      return this.replaceVars(this.model.text);
    },

    calcHeight() {
      let numberOfLineBreaks = (this.text.match(/\n/g) || []).length;
      // min-height + lines x line-height + padding + border
      let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
      return newHeight;
    },
  },
  methods: {
    copyCode() {
      let code = this.$refs.code_text;

      /* Select the textarea field */
      code.select();
      code.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the textarea field */
      document.execCommand("copy");
      this.copied = false;
      this.copied = true;
    },
    replaceVars(text) {
      let vars1 = this.$store.state.configVars;
      let vars2 = this.$store.state.fieldVars;

      let vars = {
        ...vars1,
        ...vars2,
      };

      let regex = /{{[ ]{0,}([\w_-]{1,})[ ]{0,}}}/gi;

      let result = text.replace(regex, function(...match) {
        return typeof vars[match[1]] !== "undefined" ? vars[match[1]] : "";
      });

      return result;
    },
  },
  mounted() {
    Prism.highlightAll();
  },
};
</script>

<style>

</style>
