import Content from "@/models/content/Content";

export class Button extends Content {
  // entity is a required property for all models.
  static entity = "button";

  // Reference to base entity name.
  static baseEntity = 'content' 

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      ...super.fields(),
      text: this.string("Next"),
      clean_text: this.string(""),
      fontSize: this.number(15),
      fontColor: this.attr(null),
      bgColor: this.attr(null), //"#1DA1F2"
      borderColor: this.attr(null),
      borderRadius: this.number(10),
      goto: this.attr('next'),
      goto_link: this.attr(null),
      paddingX: this.number(20),
      paddingY: this.number(8)
      
    };
  }

  static dummyData(fk_val) {

    return {
      row_id: fk_val,
      text: "Button",
    };
  }

}

export default Button;
