<template>
  <div>
    <div
      class="display-block mx-auto flex flex-col justify-center"
      :class="{'cursor-pointer': model.goto != null}"
      @click="$emit('click', model)"
    >
      <img
        v-if="model.image == 'link'"
        :src="model.image_link"
        class="display-block mx-auto max-w-full max-h-full"
        :style="{ width: width + 'px' }"
      />

      <svg-inline
        v-else
        class="h-full"
        :style="{ color: color, width: width + 'px' }"
        :image="model.image"
      />
    </div>
  </div>
</template>

<script>
import SvgInline from "@/components/SvgInline";
import Image from "@/models/content/Image";

export default {
  name: "image-content",
  components: { SvgInline },
  data() {
    return {};
  },
  props: ["content_id", "survey", "step", "align"],
  computed: {
    model() {
      return Image.find(this.content_id);
    },
    color(){

        return this.model.bgColor ?? this.survey.accentColor;
      },
    width: {
      get() {
        return this.model.width;
      },
    },
  },
  methods: {},

  mounted() {},
};
</script>

<style></style>
