<template>
  <div>
    <div v-html="parsedHtml" class="ProseMirror" :style="cssVars"></div>
  </div>
</template>

<script>
import Text from "@/models/content/Text";
import Button from "@/models/content/Button";

export default {
  components: {},

  data() {
    return {};
  },

  props: ["content_id", "survey", "step"],

  computed: {

    parsedHtml: function() {
      //get sanitized html and parse any moustache variables
      return this.replaceVars(this.model.clean_text);
    },

    cssVars() {
      return {
        "--text-color": this.color,
        "--font-size": this.fontSize + "px",
        "--font-family": this.survey.fontFamily,
        "--list-padding": this.align == "left" ? "0 1em" : "none",
        "--text-align": this.align,
      };
    },

    model() {
      return Button.find(this.content_id);
    },

    color: {
      get() {
        return this.model.fontColor ?? this.survey.fontColor;
      },
    },

    fontSize: {
      get() {
        return this.model.fontSize;
      },
    },
  },
  methods: {
    replaceVars(text) {
      let vars1 = this.$store.state.configVars;
      let vars2 = this.$store.state.fieldVars;

      let vars = {
        ...vars1,
        ...vars2,
      };

      let regex = /{{[ ]{0,}([\w_-]{1,})[ ]{0,}}}/gi;

      let result = text.replace(regex, function(...match) {
        return typeof vars[match[1]] !== "undefined" ? vars[match[1]] : "";
      });

      return result;
    },
  },

};
</script>
