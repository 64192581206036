import ActiveRecord from "@/models/ActiveRecord";
import { Content } from './ContentHierarchy';
import Row from '@/models/content/Row'; 

export class Column extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "column";

  //foreign key of the parent entity (required for reorder methods)
  static foreignKey = "row_id";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid( () => this.uuidv4() ),
      row_id: this.attr(null),
      order: this.number(99999),
      //type: this.attr(null),

      type: this.attr("CONTENT"), //what the column contains, e.g. 'TEXT' (option), 'CONTENT'

      bgColor: this.attr(null), 
      borderColor: this.attr(null),
      flex: this.string("col"), //flex direction: "col" or "row"
      align: this.string("center"), //item align: center, start (left), end (right)
      justify: this.string("evenly"), //justify: start, end, center, around, between, evenly

      paddingL: this.number(0),
      paddingR: this.number(0),
      paddingT: this.number(0),
      paddingB: this.number(0),
      marginL: this.number(0),
      marginR: this.number(0),
      row: this.belongsTo(Row, this.row_id),

      //contents: this.hasMany(Content, "column_id"),
      contents: this.morphMany(Content, "parent_id", "parent_type"),
      
    };
  }

  static dummyData(row_id){

    return {
      id: this.uuidv4(),
      row_id: row_id,
      order: 99999  //dummy value to ensure it is inserted at end of list; real order value will be determined in afterCreate hook
    }

  }

  $clone() {
    return Column.clone(this);
  }

  $replaceIds() {
    return Column.replaceIds(this);
  }

  static clone(orig) {
    let clone = { ...orig }; //Make a deep copy of step object including child options
    clone = this.replaceIds(clone);
    return clone;
  }

  //Recursively replacing primary ids and child foreign keys 
  static replaceIds(column) {
    column.id = this.uuidv4(); //Create new primary id

    column.contents.forEach( (content) => {
      content.parent_id = column.id;
      content.$replaceIds();
    });

    return column;
  }

  static afterCreate(model) {
    this.reorder(model);
  }

  static afterDelete(model) {
    this.reorder(model);
  }

}

export default Column;
