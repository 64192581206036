import  ActiveRecord from "@/models/ActiveRecord";
import { Content } from "@/models/content/ContentHierarchy";

export default class Option extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "option";

  //foreign key of the parent entity (required for reorder methods)
  static foreignKey = "step_id";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {

      id: this.uid( () => this.uuidv4() ),
      step_id: this.attr(null),
      title: this.attr(null), //deprecated 
      description: this.attr(null), //deprecated
      //title: this.attr('An option title'),
      //description: this.attr('An option subtitle'),
      image: this.attr('undraw/undraw_Post_re_mtr4'),
      image_link: this.attr(null),
      goto: this.attr('next'),
      goto_link: this.attr(null),
      order: this.number(null),
      value: this.attr(null),
      contents: this.morphMany(Content, "parent_id", "parent_type"),
      selected: this.boolean(false) // This tracks the temporary selected state of the option, but is not saved to database     
      
    };
  }

  //Sample Step data
  //fk_val = foreign key value (step_id)
  static dummyData(step_id){

    let uid = this.uuidv4();
    return {
      id: uid,
      step_id: step_id,
      contents: [
        {
          parent_type: "option",
          type: "TEXT", //content type is TEXT 
          text: "Click to edit text",
          fontSize: 18,
        }
      ],
      //title: "A brand new option",
      //description: "A new description",
      order: 99999  //dummy value to ensure it is inserted at end of list; real order value will be determined in afterCreate hook
    }

  }

  $clone() {
    return Option.clone(this);
  }

  $replaceIds() {
    return Option.replaceIds(this);
  }

  static clone(orig) {
    let clone = { ...orig }; //Make a deep copy of step object including child options
    clone = this.replaceIds(clone);
    return clone;
  }

  //Recursively replacing primary ids and child foreign keys 
  static replaceIds(option) {
    option.id = this.uuidv4(); //Create new primary id

    option.contents.forEach( (content) => {
      content.parent_id = option.id;
      content.$replaceIds();
    });

    return option;
  }

  /*
  static clone(orig){

    let option = { ...orig }; //Copy step object
    option.id = this.uuidv4();  //Replace option id

    option.contents.forEach( (content) => {
      content.id = this.uuidv4();
      content.parent_id = option.id;

    });

    return option;

  }
  */

  get answerValue(){
    //if option has set a specific answer value, else return the first content value with html stripped
    return this.value || this.contents[0].text.replace(/(<([^>]+)>)/gi, "");

  }

  static afterCreate(model){

    this.reorder(model);

  }

  static afterDelete(model){

    this.reorder(model);

  }

}
