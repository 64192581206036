<template>
  <div id="widget" class="flex">
    <!-- Think its better not to show anything while loading -->
    <div
      v-if="loadingStatus && false"
      class="flex flex-col items-center justify-center min-h-screen w-full overflow-x-hidden"
    >
      <div class="spinner mx-auto"></div>
      <div style="color: white;padding:16px">
        Loading, please wait...
      </div>

      <div @click="$emit('close-widget')" class="loading-button">Close</div>
    </div>
    <!-- End loading screen -->

    <div
      v-else-if="loaded && step.type == 'MODAL'"
      id="overlay"
      class="modal-bg flex-grow flex flex-col justify-center items-center w-full overflow-x-hidden"
    >
      <transition
        appear
        name="pop"
        tag="div"
        id="modal_pop"
        v-on:before-enter="scrollTop"
      >
        <div
          :key="step.id"
          :style="{
            'background-color': bgColor,
            padding: '5px 10px 70px 10px',
          }"
          class="modal-step relative shadow-md rounded-lg flex flex-col"
          :class="[modalClass]"
        >
          <div v-for="row in rows" :key="row.id">
            <content-row
              :key="row.id"
              :row="row"
              :survey="survey"
              :step="step"
              @click="clickButton"
              @click-option="clickOption"
            ></content-row>
          </div>

          <a
            href="https://appflowz.com"
            target="_blank"
            class="hover:no-underline absolute bottom-1 right-0 left-0 flex flex-row justify-center pt-4 pb-2 text-gray-500"
            style="font-size:13px"
          >
            Powered by 🔥 <strong class="ml-1">Appflowz</strong>
          </a>
        </div>
      </transition>
    </div>

    <div
      v-else-if="loaded && step.type != 'MODAL'"
      id="overlay"
      class="flex-grow flex flex-col w-full px-4 md:px-10 pt-4 pb-10 overflow-x-hidden bg-gray-200"
      :style="{ 'background-color': bgColor }"
    >
      <div id="content" ref="content" class="flex-grow flex flex-col">
        <div id="nav-row" class="flex flex-row-reverse justify-between">
          <div
            class="rounded-full border border-solid opacity-80 hover:opacity-100 px-2 py-2 cursor-pointer bg-gray-200"
            @click="closeWidget"
          >
            <svg-icon icon="heroicons/solid/x" class="h-6 w-6 text-gray-800" />
          </div>

          <div
            v-if="stepIndex > 0"
            class="rounded-full border border-solid opacity-80 hover:opacity-100 px-2 py-2 cursor-pointer bg-gray-200"
            @click="gotoPrev"
          >
            <svg-icon
              icon="heroicons/outline/arrow-left"
              class="h-6 w-6 text-gray-600"
            />
          </div>
        </div>

        <div id="center-panel" class="flex-grow flex flex-col justify-center">
          <div id="main-display" class=" mx-auto w-full" :key="step.id">
            <transition
              appear
              name="slide"
              tag="div"
              id="step_slide"
              v-on:before-enter="scrollTop"
            >
              <div :key="step.id">
                <div v-for="row in rows" :key="row.id">
                  <div
                    v-if="row.type == 'IMAGE'"
                    class="flex flex-wrap justify-center mx-auto mt-2"
                    :key="step.id"
                    id="option_list"
                    :style="{ width: containerWidth, 'max-width': '1100px' }"
                  >
                    <image-option
                      :key="option.id"
                      v-for="option in options"
                      :option="option"
                      :survey="survey"
                      :step="step"
                      @click="clickOption"
                      :selected="option.selected"
                    ></image-option>
                  </div>

                  <content-row
                    v-else
                    :key="row.id"
                    :row="row"
                    :survey="survey"
                    :step="step"
                    @click="clickButton"
                    @click-option="clickOption"
                  ></content-row>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <a
          href="https://appflowz.com"
          target="_blank"
          class="fixed text-gray-500 hover:text-blue-500 bg-white font-normal bottom-0 right-0 w-full hover:no-underline md:w-max md:bottom-5 md:right-10 md:rounded-md md:shadow text-center text-sm cursor-pointer border border-solid border-gray-200 px-3 py-2 hover:opacity-80"
        >
          Powered by 🔥 <strong class="ml-1">Appflowz</strong>
        </a>
      </div>
      <!--End Content -->
    </div>
    <!--End Overlay -->
  </div>
  <!-- End Widget -->
</template>

<script>
//import { FontFamily } from "@/mixins/FontFamily.js";
import ImageOption from "@/components/Display/ImageOption.vue";
//import TextOption from "@/components/Survey/Content/TextOption.vue";
import ContentRow from "@/components/Display/ContentRow.vue";

import Survey from "@/models/Survey";
import Option from "@/models/Option";
import Response from "@/models/Response";
import Visitor from "@/models/Visitor";
import SvgIcon from "@/components/SvgIcon";

//import Content from "@/models/content/Content";
//import Row from "@/models/content/Row";
import Column from "@/models/content/Column";
import Step from "@/models/Step";

//import router from "@/router/route.js";
//import router from "@/router/widget-router.js";
import EventBus from "@/event-bus.js";

export default {
  name: "widget-main",
  //mixins: [FontFamily],
  components: {
    ImageOption,
    SvgIcon,
    ContentRow,
  },
  props: ["survey_id", "stepid", "routeNameProp"],
  data() {
    return {
      loadingStatus: true,
      loaded: false,
      //stepId: null,
      stepIndex: 0,
    };
  },
  async created() {
    //this.devlog("main widget created.");

    //const html = document.documentElement;
    //html.classList.add("appflowz-reset");

    await this.init();
    this.$store.commit("SET_VISITOR", this.visitor);
  },
  beforeDestroy() {
    //const html = document.documentElement;
    //html.classList.remove("appflowz-reset");
  },
  watch: {
    $route(to, from) {
      this.stepIndex = this.findIndex(this.stepId);
      //this.init();
    },
  },
  computed: {
    survey() {
      var survey = Survey.query()
        .whereId(this.survey_id)
        .with("steps", (query) => {
          query
            .orderBy("order", "asc")
            .with("options", (query) => {
              query.orderBy("order", "asc").with("contents", (query) => {
                query.orderBy("order", "asc");
              });
            })
            .with("rows", (query) => {
              query.orderBy("order", "asc").with("columns", (query) => {
                query.orderBy("order", "asc").with("contents", (query) => {
                  query.orderBy("order", "asc");
                });
              });
            })
            .with("contents", (query) => {
              query.orderBy("order", "asc");
            });
        })
        .first();
      return survey || {};
    },

    //routeNameProp = "demo" on Template Preview; else default route is "show" for main widget
    routeName() {
      //return this.routeNameProp ?? "show";

      if (this.routeNameProp === "demo") 
        return "demo"
      else 
        return "show";

    },
    rows() {
      return this.step.rows;
    },
    contents() {
      return this.step.contents;
    },
    steps() {
      return this.survey.steps || {};
    },
    step() {
      return this.stepIndex > -1 ? this.steps[this.stepIndex] : 0;
    },
    stepId() {
      //return: a) prop || b) first step in survey
      //return this.stepid || (this.steps && this.steps[0]) ? this.steps[0]["id"] : null;
      return this.stepid || this.steps[0]?.id || null;
    },
    options() {
      return this.step.options;
    },
    visitorData() {
      //Note: 'user_id' in the pixel == 'customer_id' in our Visitor model
      //(user_id is the foreign key for the User who own the visitor )

      let config = this.$store.state.configVars;

      return {
        user_id: (this.survey || {}).user_id || null,
        customer_id: config.user_id || null,
        email: config.email || null,
        name: config.name || null,
      };
    },
    visitor() {
      return Visitor.query()
        .where("email", this.visitorData.email)
        .where("user_id", this.survey.user_id)
        .first();
    },

    //STYLE PROPERTIES
    font() {
      return this.survey.font;
    },
    fontColor() {
      return this.survey.fontColor;
    },
    bgColor() {
      return this.step.bgColor || this.survey.bgColor;
    },
    containerWidth() {
      return this.step.containerWidth();
    },
    /*
    modalWidth() {
      if (this.step.size === "sm") return 500;
      if (this.step.size === "md") return 700;
      if (this.step.size === "lg") return 900;
      else return "700px";
    }, */
    modalClass() {
      if (this.step.size === "sm") return "modal-sm";
      if (this.step.size === "md") return "modal-md";
      if (this.step.size === "lg") return "modal-lg";
      else return "modal-md";
    },
  },
  methods: {
    async init() {
      //this.devlog("init called");
      this.loadingStatus = true;
      var id = this.survey_id || null;

      if (id === null) {
        console.log("Error: survey id missing.");
        this.loadingStatus = false;
        this.$destroy();
        return;
      }

      //if survey is not already loaded in state, request it from the server
      if (Object.keys(this.survey).length < 1) {
        await Survey.api().get("/api/survey/" + id, {
          dataKey: "data",
        });
        //this.devlog("requesting survey from server");
      }

      //if server failed to return a survey
      if (Object.keys(this.survey) < 1) {
        //this.devlog("Survey not found or failed to load.");
        this.loadingStatus = false;
        this.closeWidget();
        return;
      }

      //if visitor is not already loaded in state, try to load it from server
      if (this.visitor === null) {
        //this.devlog("attempting to load visitor from server");
        await Visitor.api().get(
          "/api/visitor/" +
            this.visitorData.email +
            "/user/" +
            this.survey.user_id,
          {
            dataKey: "data",
          }
        );

        //if still null, create new visitor and save to server
        if (this.visitor === null) {
          //this.devlog("no visitor found on server, creating new visitor");
          await Visitor.create({ data: this.visitorData });

          try {
            //this.devlog("saving new visitor to backend database");
            var post = await Visitor.api().post(
              "/api/visitor/save",
              this.visitor
            );
          } catch (err) {
            //Log error
          }

          /*
          if (post.response.status == 200) {
            //success
          } else {
            //error
          }
          */
        }
      }

      if (this.survey != {} && this.visitor !== null) {
        this.loadResponses();
        this.stepIndex = this.findIndex(this.stepId);
        this.loaded = true;
      }

      this.loadingStatus = false;
    },
    scrollTop() {
      //console.log("scrolling");
      var content = this.$refs.content;

      if (typeof content !== "undefined") {
        content.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
      }
    },
    async loadResponses() {
      //Load previous responses, if any, from server into Vuex-ORM
      await Response.api().get(
        `/api/response/populate/survey/${this.survey.id}/visitor/${this.visitor.id}`,
        { dataKey: "data" }
      );

      //loop through steps and populate any previously selected options 
      this.steps.forEach((step) => {
        step.options.forEach((option) => {
          //reset any old values sitting in our Vuex-ORM state to false
          Option.update({ where: option.id, data: { selected: false } });
          
          let responses = Response.query()
            .where("survey_id", this.survey.id)
            .where("step_id", step.id)
            .get();
          responses.forEach( response => {

          if (response.value == option.answerValue) {
            this.devlog("true: " + response.value);
            Option.update({ where: option.id, data: { selected: true } });
          }

          })
        });
      });

/*
      responses.forEach((response) => {
        //const step = this.steps[response.step_id];

        const step = Step.query()
          .whereId(response.step_id)
          .with("options", (query) => {
            query.orderBy("order", "asc").with("contents", (query) => {
              query.orderBy("order", "asc");
            });
          })
          .first();

        console.dir(step);

        step.options.forEach((option) => {
          console.log("yolo");

          //reset any old values sitting in our Vuex-ORM state to false
          Option.update({ where: option.id, data: { selected: false } });

          //let option_value = ; //option.value || option.title;
          if (response.value == option.answerValue) {
            this.devlog("true: " + response.value);
            Option.update({ where: option.id, data: { selected: true } });
          }
        });
      });
      */
    },

    findIndex(step_id) {
      return this.steps.findIndex((step) => step.id === step_id) || 0;
    },
    gotoNext() {
      //increment step
      var nextIndex = this.stepIndex + 1;

      if (nextIndex < this.steps.length) {
        var nextStep = this.steps[nextIndex];

        this.$emit("router-push", {
          name: this.routeName,
          params: { id: nextStep.id },
        });
      }
    },
    gotoPrev() {
      if (this.stepIndex > 0) {
        this.$router.back();
        /*
        var prevIndex = this.stepIndex - 1;
        var prevStep = this.steps[prevIndex];

        this.$emit("router-push", {
          name: "show",
          params: { id: prevStep.id },
        });
        */
      }
    },
    closeWidget() {
      this.$emit("close-widget", this.survey);
      //this.$destroy();
    },
    //Stores the answer choice selected by the user as a Response model
    //option = the answer choice selected by the user
    async saveSelected(option) {
      //update option selected state to true (triggers 'selected' UI)
      Option.update({ where: option.id, data: { selected: true } });

      //save Response model to Vuex-ORM state
      let response = await Response.insertNew({
        data: {
          visitor_id: this.visitor.id,
          step_id: this.step.id,
          survey_id: this.survey.id,

          //Save custom value if any, else save option.title
          value: option.answerValue, //option.value || option.title,
        },
      });

      //save response to server
      await Response.api().post("/api/response/save", response);
    },

    //Removes a previously selected answer choice
    async removeSelected(option) {
      //Update option selected state to false (removes 'selected' UI)
      Option.update({ where: option.id, data: { selected: false } });

      let response = Response.query()
        .where("visitor_id", this.visitor.id)
        .where("step_id", this.step.id)
        .where("value", option.answerValue)
        .first();

      //Delete response from server
      await Response.api().delete("/api/response/delete/" + response.id);

      response.$delete();
    },

    //Removes all previous responses for a given step/question
    removeAll(step) {
      //Delete Response models for current step
      Response.delete((response) => {
        return (
          response.visitor_id == this.visitor.id &&
          response.step_id == this.step.id
        );
      });

      //Reset all option selected states to false (triggers UI change)
      step.options.forEach((option) => {
        Option.update({ where: option.id, data: { selected: false } });
      });
    },

    //handle a button click
    async clickButton(button) {
      await EventBus.$emit("submit");

      //make sure no field errors on the current step
      if (!this.step.errors.length) {
        this.routeClick(button);
      }
    },

    //handle an option click
    async clickOption(option) {
      if (this.step.allow_many) {
        //This looks a little wonky, but it simply toggles the selected state of the Option model
        //between true/false, which trigges the 'selected' UI changes in SurveyOptionDisplay
        let updated = await Option.update({
          where: option.id,
          data: { selected: !option.selected },
        });

        if (updated.selected) {
          this.saveSelected(option);
        } else {
          this.removeSelected(option);
        }
      } else {
        //Delete any previous responses for the current step
        this.removeAll(this.step);

        //Save the current selection
        this.saveSelected(option);

        //"Hold" the current page 1.2s to show the selected answer before forwarding to next step
        setTimeout(() => {
          this.routeClick(option);
        }, 1200);
      }
    },

    //route a button/option click to the location specified in the "goto" field
    async routeClick(button) {
      //make sure no field errors on the current step
      if (!this.step.errors.length) {

        console.log('goto: ' + button.goto)

        if (button.goto == "close") {
          this.closeWidget();
        } else if (button.goto == "link" && button.goto_link != "") {
          //if link to external site, then go there
        } else if (button.goto == "prev") {
          this.gotoPrev();
          window.location.href = button.goto_link;
        } else if (button.goto == "next") {
          //go to next step
          this.gotoNext();  
        } else if (Number.isInteger(+button.goto)) {
          //linked to a specific step
          var goto_step = null;

          //find the linked step based on the auto_id
          this.steps.forEach( row => {
            if (row.auto_id == button.goto) goto_step = row;
          });

          if (goto_step !== null){
          //const step = await Step.query().where('survey_id', this.survey.id).where('auto_id', button.goto).first();
          console.log('going to step ' + goto_step.id);

          this.$emit("router-push", {
            name: this.routeName,
            params: { id: goto_step.id },
          });

          } //goto_step not found; e.g. step was deleted
          else this.gotoNext();

        } else { //if button.goto === null or missing
          this.gotoNext();
        }
      }
    },

    //get the parent step for a piece of content
    getStep(content) {
      let column = Column.find(content.parent_id);
      let row = column.row;
      let step = Step.find(row.parent_id);
      return step;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/widget.css";
@import "@/assets/editor.scss";
</style>
