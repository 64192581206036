import { Content } from './ContentHierarchy' 

export class Video extends Content {
  // entity is a required property for all models.
  static entity = "video";

  // Reference to base entity name.
  static baseEntity = "content";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields(),
      text: this.string(""),
      clean_text: this.string(""),
      size: this.attr("md"), //'sm', 'md', 'lg'
      bgColor: this.attr(null),

    };
  }
}

export default Video;
