//Router file for Widget build
import Vue from "vue";
import VueRouter from "vue-router";
import Widget from "@/Widget.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/s/:id?",
    name: "show",
    props: true,
    //component: () => import("@/Widget.vue"),
    component: Widget
  }
];

const router = new VueRouter({
  mode: "hash", //"history",
  base: window.location.pathName, //set the base url to the current page url
  routes
});

export default router;
