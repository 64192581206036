import ActiveRecord from "@/models/ActiveRecord";


//FormAnswers are saved in the Response table

export default class FormAnswer extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "form_answer";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      survey_id: this.attr(null),
      step_id: this.attr(null),
      field_id: this.attr(null), //content id of the form field that the user is responding to
      visitor_id: this.attr(null),
      value: this.attr(null)
    };
  }
}
