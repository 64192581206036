import ActiveRecord from "@/models/ActiveRecord";

export default class VisitorField extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "visitor_field";

  //foreign key of the parent entity
  static foreignKey = "visitor_id";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      visitor_id: this.attr(null),
      field_name: this.string(''),
      field_value: this.attr(null),
    };
  }
}
