var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model.field_type == null)?_c('div',[_vm._v("Input type not set.")]):_c('div',[_c('BaseInlineErrors',{style:({ 'font-family': _vm.survey.fontFamily }),attrs:{"errors":_vm.errors.field}}),((_vm.model.field_type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"flex-1 appearance-none \n     w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base border focus:border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 ",style:({
      'font-family': _vm.survey.fontFamily,
      'border-color': _vm.borderColor,
      'background-color': _vm.bgColor,
      'border-radius': _vm.borderRadius + 'px',
      'font-size': _vm.fontSize + 'px',
      padding: _vm.paddingY + 'px ' + _vm.paddingX + 'px',
    }),attrs:{"placeholder":_vm.placeholder,"name":_vm.model.name,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.field)?_vm._i(_vm.field,null)>-1:(_vm.field)},on:{"blur":_vm.validate,"change":function($event){var $$a=_vm.field,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.field=$$a.concat([$$v]))}else{$$i>-1&&(_vm.field=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.field=$$c}}}}):((_vm.model.field_type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"flex-1 appearance-none \n     w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base border focus:border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 ",style:({
      'font-family': _vm.survey.fontFamily,
      'border-color': _vm.borderColor,
      'background-color': _vm.bgColor,
      'border-radius': _vm.borderRadius + 'px',
      'font-size': _vm.fontSize + 'px',
      padding: _vm.paddingY + 'px ' + _vm.paddingX + 'px',
    }),attrs:{"placeholder":_vm.placeholder,"name":_vm.model.name,"type":"radio"},domProps:{"checked":_vm._q(_vm.field,null)},on:{"blur":_vm.validate,"change":function($event){_vm.field=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"flex-1 appearance-none \n     w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base border focus:border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 ",style:({
      'font-family': _vm.survey.fontFamily,
      'border-color': _vm.borderColor,
      'background-color': _vm.bgColor,
      'border-radius': _vm.borderRadius + 'px',
      'font-size': _vm.fontSize + 'px',
      padding: _vm.paddingY + 'px ' + _vm.paddingX + 'px',
    }),attrs:{"placeholder":_vm.placeholder,"name":_vm.model.name,"type":_vm.model.field_type},domProps:{"value":(_vm.field)},on:{"blur":_vm.validate,"input":function($event){if($event.target.composing){ return; }_vm.field=$event.target.value}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }