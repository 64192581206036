// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SvgInline_svgimage_3uGHF{max-height:100%;max-width:100%;display:block;margin-left:auto;margin-right:auto}circle[fill=\"#6c63ff\"],circle[fill=\"#eee666\"],path[fill=\"#6c63ff\"],path[fill=\"#eee666\"],polygon[fill=\"#6c63ff\"],polygon[fill=\"#eee666\"],rect[fill=\"#6c63ff\"],rect[fill=\"#eee666\"]{fill:currentColor}", ""]);
// Exports
exports.locals = {
	"svgimage": "SvgInline_svgimage_3uGHF"
};
module.exports = exports;
