import ActiveRecord from "@/models/ActiveRecord";

import { Text } from './ContentHierarchy' 
import { Image } from './ContentHierarchy' 
import { Button } from './ContentHierarchy' 
import { Emoji } from './ContentHierarchy' 
import { FormField } from './ContentHierarchy' 
import { CodeField } from './ContentHierarchy' 
import { Video } from './ContentHierarchy' 

export class Content extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "content";

  static types () {
    return {
      CONTENT: Content,
      TEXT: Text,
      IMAGE: Image,
      BUTTON: Button,
      EMOJI: Emoji,
      FIELD: FormField,
      CODE: CodeField,
      VIDEO: Video
    }
  }

  //foreign key of the parent entity
  static foreignKey = "parent_id";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid( () => this.uuidv4() ),
      //column_id: this.attr(null),
      parent_id: this.attr(null),
      parent_type: this.attr(null),
      //parent: this.morphTo('parent_id', 'parent_type'),
      order: this.number(99999),
      type: this.attr('CONTENT'),
      marginTop: this.number(2),
      marginBottom: this.number(2)
    };
  }

  static dummyData(parent_id){

    return {
      id: this.uuidv4(),
      parent_id: parent_id,
      order: 99999  //dummy value to ensure it is inserted at end of list; real order value will be determined in afterCreate hook
    }

  }

  $clone() {
    return Content.clone(this);
  }

  $replaceIds() {
    return Content.replaceIds(this);
  }

  static clone(orig) {
    let clone = { ...orig }; //Make a deep copy of step object including child options
    clone = this.replaceIds(clone);
    return clone;
  }

  //Replace primary id
  static replaceIds(content) {
    content.id = this.uuidv4(); //Create new primary id
    return content;
  }

  static afterCreate(model){

    this.reorder(model);

  }

  static afterDelete(model){

    this.reorder(model);

  }

}

export default Content;
