<template>
  <svg-inline :image="iconFile"></svg-inline>
</template>


<script>
import SvgInline from "@/components/SvgInline";
export default {
  components: {SvgInline},
  props: ["icon"],
  computed: {
    iconFile(){
      return 'icons/' + this.icon;
    }
  }
};
</script>
