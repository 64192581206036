// User Model

import { Model } from '@vuex-orm/core'

export default class User extends Model {
  // entity is a required property for all models.
  static entity = 'user'

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields () {
    return {
      id: this.attr(null),
      account: this.uid(() => this.uuidv4()),
      firstName: this.string(''),
      lastName: this.string(''),
      email: this.attr('')
    }
  }

  // Get full name of the user.
  get fullName () {
    return `${this.firstName} ${this.lastName}`
  }

  // Add the given prefix to the user's full name.
  prefix (prefix) {
    return `${prefix} ${this.fullName}`
  }
}

