import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
import auth from "@/store/auth-sanctum";
//import activeSurvey from "@/store/active-survey.js";
//import * as user from "@/store/auth-passport.js";
import * as notification from "@/store/notification.js";
import VuexORMAxios from "@vuex-orm/plugin-axios";

//VUEX-ORM
import VuexORM from "@vuex-orm/core";
import { Database } from "@vuex-orm/core";
VuexORM.use(VuexORMAxios, { axios });

//Import Models and Register them in Vuex-ORM
const database = new Database();
import User from "@/models/User";
database.register(User);

import Survey from "@/models/Survey";
database.register(Survey);

import Step from "@/models/Step";
database.register(Step);

import OldButton from "@/models/OldButton";
database.register(OldButton);

import Option from "@/models/Option";
database.register(Option);

import Response from "@/models/Response";
database.register(Response);

import Visitor from "@/models/Visitor";
database.register(Visitor);

import VisitorField from "@/models/VisitorField";
database.register(VisitorField);

import { Content, Button, Image, Text, Emoji, FormField, CodeField, Video } from '@/models/content/ContentHierarchy'
database.register(Content);
database.register(Text);
database.register(Image);
database.register(Button);
database.register(Emoji);
database.register(FormField);
database.register(CodeField);
database.register(Video);

import Column from "@/models/content/Column";
database.register(Column);

import Row from "@/models/content/Row";
database.register(Row);

import FormAnswer from "@/models/FormAnswer";
database.register(FormAnswer);

import Integration from "@/models/Integration";
database.register(Integration);


Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    notification,
    auth,
    //survey
  },
  state: {
    dragging: false, //true when content items are being dragged
    chooserVisible: false, //true when content chooser is shown
    modalOpen: false, //true when (ImageModal?) is visible
    configVars: {}, //object that contains variables set in the pixel
    fieldVars: {}, //object that contains field names and user values (e.g. {email: "bob@example.com"})
    visitor: {}, //object that contains model for the current visitor (from pixel) 
  },
  mutations: {

    SET_DRAG_STATE(state, dragState) {
      state.dragging = dragState;
    },

    //Content Chooser Panel
    SET_CHOOSER_STATE(state, visibleState) {
      state.chooserVisible = visibleState;
    },

    //Image Select Modal
    SET_MODAL_STATE(state, boolean) {
        state.modalOpen = boolean;
    },

    SET_CONFIG_VARS(state, obj) {
        state.configVars = obj;
    },

    UPDATE_FIELD_VARS(state, payload) {
      Vue.set(state.fieldVars, payload.key, payload.value);
    },

    SET_VISITOR(state, obj) {
          state.visitor = obj;
    }

  },
  actions: {},
  getters: {},
});
