import ActiveRecord from "@/models/ActiveRecord";

export default class OldButton extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "button";

  //foreign key of the parent entity
  static foreignKey = "survey_id";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {

      id: this.uid( () => this.uuidv4() ),
      survey_id: this.attr(null),
      next_text: this.string("Continue"),
      skip_text: this.string("Skip"),
      welcome_text: this.string("Get Started"),
      close_text: this.string("Close"),
      position_x: this.string("right"), //right, left, center
      position_y: this.string("top"), //top, bottom    
      fontColor: this.string("#2c3e50"),
      bgColor: this.string("#FFFFFF"), 
      borderColor: this.string("#E5E7EB"),
      
    };
  }

}
