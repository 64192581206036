<template>
  <div
    class="option-object px-4 mt-4"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <!-- START OPTION PANEL-->
    <div
      class="break-words rounded-lg shadow h-full hover:shadow-lg cursor-pointer px-4 pb-4 "
      :style="{ 'background-color': panelColor, 'border-color': borderColor }"
      :class="[
        borderClass,
        panelWidthClass,
        { 'shadow-lg': option.selected, 'image-option-mouseover': hover || option.selected }
      ]"
      @click="localClickHandler"
    >
      <!--START MAIN VIEW -->
      <div>
        <div
          v-if="step.allow_many || this.option.selected"
          class="flex flex-row-reverse pt-2 pb-2 md:pb-3"
        >
          <svg-icon
            icon="heroicons/solid/check-circle"
            class="h-6 w-6 md:h-7 md:w-7"
            :style="{ color: checkColor }"
          ></svg-icon>
        </div>

        <div v-else class="h-6 w-6 md:h-12 md:w-12"></div>

        <div :class="imageHeightClass" class="flex flex-col justify-center">
          <img
            v-if="option.image == 'link'"
            :src="option.image_link"
            class="display-block mx-auto max-w-full max-h-full"
          />
          <svg-inline
            v-else
            class="display-block mx-auto h-full"
            :copyAttrs="true"
            :style="{ color: accentColor }"
            :image="option.image"
          />
        </div>

        <div class="p-4"
            v-for="content in contents"
            :key="content.id"
          >
            <h5 class="text-base my-auto text-center">
              <div
                v-html="content.clean_text"
                class="block mx-auto px-5 text-center ProseMirror"
                :style="{ '--text-color': content.fontColor || survey.fontColor2, '--font-size': content.fontSize + 'px', '--font-family': survey.fontFamily}"
              ></div>
            </h5>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { FontFamily } from "@/mixins/FontFamily.js";
import SvgInline from "@/components/SvgInline";
import SvgIcon from "@/components/SvgIcon";

export default {
  //mixins: [FontFamily],
  name: "option-display",
  components: { SvgInline, SvgIcon },
  props: ["survey", "step", "option"],
  data() {
    return {
      hover: false,
      // selected: this.option.selected,
    };
  },
  computed: {
    contents() {
      return this.option.contents;
    },
    imageHeightClass() {
      return this.step.imageHeightClass;
    },
    borderClass() {
      return this.option.selected
        ? "border-2 border-solid border-blue-300"
        : "border border-solid border-gray-200";
    },
    panelWidthClass() {
      return this.step.panelWidthClass;
    },
    accentColor() {
      return this.survey.accentColor;
    },
    checkColor() {
      return this.option.selected ? this.survey.selectedColor  : "#d4d7dd";
    },
    panelColor() {
      return this.survey.panelColor;
    },
    borderColor() {
      return this.option.selected ? this.survey.selectedColor : this.survey.borderColor;
    },
    font() {
      return this.survey.font;
    },
    size() {
      return this.step.size;
    },
    image() {
      return this.option.image;
    },
  },
  methods: {
    localClickHandler() {
      // The "selected" property in not part of our option model schema,
      // so we need to use Vue.set to initialize the property if it does not already exist
      //this.$set(this.option, "selected", this.selected);

      this.$emit("click", this.option);
    },
  },
};
</script>

<style></style>
