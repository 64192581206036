import { Content } from './ContentHierarchy' 
//import Content from "@/models/content/ContentHierarchy";

export class Image extends Content {
  // entity is a required property for all models.
  static entity = "image";

  // Reference to base entity name.
  static baseEntity = "content";

  // Call `super.fields()`` to merge fields.
  static fields() {
    return {
      ...super.fields(),
      image: this.attr("undraw/undraw_Post_re_mtr4"),
      image_link: this.attr(null),
      size: this.attr("md"), //'sm', 'md', 'lg'
      width: this.attr(150),
      bgColor: this.attr(null),
      goto: this.attr('next'),
      goto_link: this.attr(null)
    };
  }
}

export default Image;
