<template>
  <div class="content-row " :style="{ margin: margin }">
    <div :style="{ 'max-width': maxWidth + 'px' }" class="m-auto ">
      <div :key="row.id" :style="{display:'grid', 'grid-template-columns': `repeat(${columns.length}, minmax(0, 1fr))`, gap: '4px'}">
        
        <!-- Load Columns -->
        <div v-for="column in row.columns" :key="column.id">

          <!-- OPTION ROW -->
          <div
            v-if="column.type=='OPTION'"
            class="flex flex-wrap mx-auto"
            :key="step.id"
            id="option_list"
            :style="{ 'max-width': '910px' }"
            :class="[
              alignClass(column),
              justifyClass(column),
              flexClass(column),
            ]"
          >
            <text-option
              :key="option.id"
              v-for="option in options"
              :option="option"
              :survey="survey"
              :step="step"
              @click="(option) => $emit('click-option', option)"
              :selected="option.selected"
            ></text-option>
          </div>

          <!-- CONTENT ROW -->
          <div
            v-else
            class="flex h-full"
            :class="[
              alignClass(column),
              justifyClass(column),
              flexClass(column),
            ]"
          >
            <div
              v-for="content in column.contents"
              :key="content.id"
              class="content-div p-px flex-initial"
              :class="{
                'w-full': content.type == 'FIELD' || content.type == 'CODE' ,
              }"
            >
              <component
                v-if="column.contents.length > 0"
                v-bind:is="content.type + 'Content'"
                @click="
                  (model) => {
                    $emit('click', model);
                  }
                "
                :content_id="content.id"
                :survey="survey"
                :step="step"
                :style="{
                  'margin-top': content.marginTop + 'px',
                  'margin-bottom': content.marginBottom + 'px',
                }"
              >
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
//import Row from "@/models/content/Row";
//import Column from "@/models/content/Column";
import TextOption from "@/components/Display/TextOption.vue";
import TEXTContent from "@/components/Display/Text";
import IMAGEContent from "@/components/Display/Image";
import BUTTONContent from "@/components/Display/Button";
import CODEContent from "@/components/Display/Code";
import VIDEOContent from "@/components/Display/Video";
import FIELDContent from "@/components/Display/Field";


export default {
  name: "content-row",
  components: {
    TEXTContent,
    IMAGEContent,
    BUTTONContent,
    CODEContent,
    VIDEOContent,
    FIELDContent,
    TextOption,
    //ContentColumn,
  },
  data() {
    return {
      loaded: false,
    };
  },
  props: ["row", "survey", "step"],
  computed: {
    columns() {
      return this.row.columns;
    },
    options(){
      return this.step.options;
    },
    gridColumns() {
      let n = this.columns.length;
      return `grid-cols-${n}`;
    },
    width() {
      return this.row.width || "auto";
    },
    marginTop() {
      return this.row.marginTop;
    },
    marginBottom() {
      return this.row.marginBottom;
    },
    margin() {
      return `${this.marginTop}px 0 ${this.marginBottom}px 0`;
    },
    maxWidth() {
      return Number.isInteger(this.row.maxWidth) ? this.row.maxWidth : null;
    },
  },
  methods: {
    flexClass(column) {
      if (typeof column.flex === "string") {
        return `flex-${column.flex}`;
      } else return "flex-col";
    },
    alignClass(column) {
      if (typeof column.justify === "string") {
        return `items-${column.align}`;
      } else return "items-center";
    },
    justifyClass(column) {
      if (typeof column.justify === "string") {
        return `justify-${column.justify}`;
      } else return "justify-evenly";
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/editor.scss";
</style>
