var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-button hover:no-underline cursor-pointer inline-block border border-solid border-transparent",class:{ 'hover-active': _vm.hover, 'no-hover': !_vm.hover },style:({
      color: _vm.model.fontColor,
      'font-family': _vm.survey.fontFamily,
      'background-color': _vm.bgColor,
      'border-color': _vm.borderColor,
      'border-radius': _vm.borderRadius + 'px',
      padding: _vm.paddingY + 'px ' + _vm.paddingX + 'px',
    }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.$emit('click', _vm.model)}}},[_c('div',{staticClass:"ProseMirror",style:(_vm.cssVars),domProps:{"innerHTML":_vm._s(_vm.text)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }