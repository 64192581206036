import ActiveRecord from "@/models/ActiveRecord";
import Option from "@/models/Option";
import Row from "@/models/content/Row";
import { Content } from "@/models/content/ContentHierarchy";

export default class Step extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "step";

  //foreign key of the parent entity
  static foreignKey = "survey_id";

  err = [];

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      survey_id: this.attr(null),

      title: this.attr(null), //optional title for reports/exports
      ref_id: this.attr(null), //optional reference id for the step
      auto_id: this.number(null), //auto-incrementing id for step, starting at 0

      type: this.attr("FULL"), //'MODAL' or 'FULL'
      bgColor: this.attr(null),

      size: this.attr("md"), //image panel/modal size: 'sm', 'md', 'lg'

      allow_many: this.boolean(false), //whether to allow user to select more than one answer choice
      order: this.number(null),
      required: this.boolean(false),

      options: this.hasMany(Option, "step_id"),
      rows: this.morphMany(Row, "parent_id", "parent_type"),
      contents: this.morphMany(Content, "parent_id", "parent_type"),
    };
  }
/*
  static mutators() {
    var self = this;
    return {
      ref_id(value) {
        return value ?? self.generateRandomString(6);
      },
    };
  }
  */

  //temporary property to store/track any form field validation errors on the current step
  get errors() {
    return this.err;
  }

  set errors(value) {
    this.err = value;
    console.dir(this.err);
  }

  //Create auto incrementing ids that are unique to this survey
  static setAutoId(record, fkey=null) {

    fkey = fkey || this.foreignKey;
    let fk_val = record[fkey];

    //Get all records that have the same parent, ordered by order field
    var steps = this.getPeerRecords(fkey, fk_val);

    let max = 0;

    if (steps.length > 0) {
      //get column that contains existing ids
      let ids = steps.map((row) => {
        return row.auto_id;
      });

      console.dir(ids);

      // add +1 to current highest id
       max = Math.max(...ids) + 1;
    }

    this.update({ where: record.id, data: {auto_id: max } });

  }

  static generateRandomString(length = 6) {
    return (
      "step_" +
      Math.random()
        .toString(20)
        .substr(2, length)
    );
  }

  //Sample Step data
  //fk_val = foreign key value (survey_id)
  static dummyData(fk_val) {
    let step_id = this.uuidv4();

    return {
      id: step_id,
      survey_id: fk_val,
      order: 99999, //dummy value to ensure it is inserted at end of list; real order value will be determined in afterCreate hook
      options: [], //Option.seed(step_id, 2, true),
      rows: [],
      contents: [],
    };
  }

  get imageHeightClass() {
    if (this.size === "xs") return "h-16 sm:h-16 md:h-16";
    if (this.size === "sm") return "h-20 sm:h-20 md:h-20";
    if (this.size === "md") return "h-20 sm:h-24 md:h-28";
    if (this.size === "lg") return "h-20 sm:h-32 md:h-40";
    else return "h-20 sm:h-24 md:h-28";
  }

  //The name of the class for the given panel size
  get panelWidthClass() {
    if (this.size === "xs") return "panel-xs";
    if (this.size === "sm") return "panel-sm";
    if (this.size === "lg") return "panel-lg";
    else return "panel-md";
  }

  get hasOptions() {
    //returns true if step contains any options
    return this.options.length > 0;
  }

  //num_items = number of answer choices, e.g. options.length
  containerWidth(num_items = null) {
    num_items = num_items || this.options.length;
    //console.log ('num_items: ' + num_items + 'len: ' + this.options.length)
    if (num_items > 3) {
      var max_rows = Math.ceil(num_items / 2);
      var padding = 32; // Padding size of option-object wrapper "px-4"=32px

      //panel size at the largest media breakpoint
      var max_panel_size = this.maxPanelWidth();
      //console.log('max panel:' + max_panel_size);
      var pixels = max_rows * (max_panel_size + padding);
      return pixels + "px";
    } else return "100%";
    //return '1086px';
    //return '1022px';
  }

  /* Returns the width the option panel at the largest media breakpoint */
  /* IMPORTANT: This needs to be consistent with the CSS in the Widget/Builder/Preview */
  maxPanelWidth() {
    if (this.size === "xs") return 200;
    if (this.size === "sm") return 250;
    if (this.size === "lg") return 400;
    else return 330;
  }

  $clone() {
    return Step.clone(this);
  }

  $replaceIds() {
    return Step.replaceIds(this);
  }

  static clone(orig) {
    let clone = { ...orig }; //Make a deep copy of step object including child options
    clone = this.replaceIds(clone);
    return clone;
  }

  //Recursively replacing primary ids and child foreign keys
  static replaceIds(step) {
    step.id = this.uuidv4(); //Create new primary id

    step.options.forEach((option) => {
      option.step_id = step.id;
      option.$replaceIds();
    });

    step.rows.forEach((row) => {
      row.parent_id = step.id;
      row.$replaceIds();
    });

    step.contents.forEach((content) => {
      content.parent_id = step.id;
      content.$replaceIds();
    });

    return step;
  }

  static afterCreate(model) {

    //re-sync step order
    this.reorder(model);

    //create auto incrementing id
    if (model.auto_id === null || model.auto_id == 0){
      this.setAutoId(model);
    }

    //create ref_id for this step for integration
    if (model.ref_id === null || model.ref_id === '' ){
      let ref_id = this.generateRandomString(6);
      this.update({ where: model.id, data: {ref_id: ref_id } });
    }
    
  }

  static afterDelete(model) {
    this.reorder(model);
  }


}
