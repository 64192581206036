<template>
  <div ref="div"></div>
</template>

<style module>
.svgimage {
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
path[fill="#eee666"], polygon[fill="#eee666"], circle[fill="#eee666"], rect[fill="#eee666"]{
  fill: currentColor;
}
path[fill="#6c63ff"], polygon[fill="#6c63ff"], circle[fill="#6c63ff"], rect[fill="#6c63ff"]{
  fill: currentColor;
}
</style>

<script>
export default {
  name: "svg-inline",
  data() {
    return {};
  },
  //image = location under assets/svg directory where svg image is located, e.g. undraw/undrawCloud
  //copyAttrs = whether to copy attributes from div to svg on initial mount.
  //NOTE that copied attributes are NOT reative! IF you want to react to class/style changes leave this false
  props: {
    image: String,
    copyAttrs: Boolean,
    color: String
  },
  methods: {
    async inline() {
      var div = this.$refs.div;
      var svgfile = require(`@/assets/svg/${this.image}.svg`);
      var svg = await this.getSVGFromFile(svgfile);
      this.applyStyles(svg);

      //if copyAttrs ===true, copy attributes from div on to svg (note this is not fully reactive
      //and will NOT update on class and style changes).
      if (this.copyAttrs) {
        this.copyAttributes(div, svg);
      }

      await div.appendChild(svg);
      //console.log("SVGInline: appended.");
    },
    async getSVGFromFile(file) {
      //Based on SVG inline code from http://jsfiddle.net/2b1nt6py/1/

      //Retrieve the SVG file indicated by image prop
      var response = await fetch(file);
      var text = await response.text();

      // Parse and return SVG in memory
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(text, "text/xml");
      var svg = xmlDoc.getElementsByTagName("svg")[0];

      // Remove any invalid XML tags as per http://validator.w3.org
      svg.removeAttribute("xmlns:a");

      return svg;
    },
    copyAttributes(source, target) {
      [...source.attributes].forEach((attr) => {
        target.setAttribute(attr.nodeName, attr.nodeValue);
      });
    },
    applyStyles(elem) {
      // Apply styles to our newly added svg
      //var div = this.$refs.div;
      //var svg = this.$el.querySelector("svg");
      elem.removeAttribute("height");
      elem.removeAttribute("width");

      //svg.setAttribute("class", this.class);
      elem.classList.add(this.$style.svgimage);

      //console.log('SVGInline: applied.');
    },
  },
  watch: {
    // if image prop changes, delete old svg and inline the new one
    image: function() {
      var svg = this.$el.querySelector("svg");
      svg.remove();
      this.inline();
    },
  },
  mounted() {
    this.inline();
  },
  updated() {
    this.applyStyles();
  },
};
</script>
