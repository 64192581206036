<template>
  <div
    class="option-object px-2 my-2"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >

    <!-- START OPTION PANEL-->
    <div
      class="break-words bg-white rounded-lg h-full px-2 py-2 flex flex-col justify-center border border-solid hover:shadow-sm cursor-pointer"
      :style="{ 'background-color': panelColor, 'border-color': borderColor }"
      :class="[
        borderClass,
        { 'text-option-mouseover': hover },
        { 'shadow-sm': option.selected },
      ]"
      @click="localClickHandler"
    >
      <!--START MAIN VIEW -->

      <div
        
        class="flex flex-row-reverse"
      >
        <svg-icon v-if="step.allow_many || this.option.selected"
          icon="heroicons/solid/check-circle"
          class="h-6 w-6 md:h-7 md:w-7"
          :style="{ color: checkColor }"
        ></svg-icon>

      <div
        v-for="content in contents"
        :key="content.id"
        :style="{
          'margin-top': content.marginTop + 'px',
          'margin-bottom': content.marginBottom + 'px',
        }"
        class="px-2"
      >
        <h5 class="text-base my-auto ">
          <div
            v-html="content.clean_text"
            class="ProseMirror block mx-auto px-5 "
            :style="{ '--text-color': content.fontColor || survey.fontColor2, '--font-size': content.fontSize + 'px', '--font-family': survey.fontFamily}"

          ></div>
        </h5>
      </div>
</div>
    </div>
  </div>
</template>

<script>
import Option from "@/models/Option";
import SvgIcon from "@/components/SvgIcon";


export default {

  components: {
    SvgIcon
  },
  data() {
    return {
      hover: false,

    };
  },
  props: ["survey", "step", "option"],
  computed: {
    borderClass() {
      return this.option.selected
        ? "border-2 border-solid"
        : "border border-solid ";
    },
    contents(){
      return this.option.contents;
    },
    accentColor() {
      return this.survey.accentColor;
    },
    checkColor() {
      return this.option.selected ? this.survey.selectedColor  : "#d4d7dd";
    },
    panelColor() {
      return this.survey.panelColor;
    },
    borderColor() {
      return this.option.selected ? this.survey.selectedColor : this.survey.borderColor;
    },
    font() {
      return this.survey.font;
    },
    size() {
      return this.step.size;
    },
    /*
    goto() {
        return this.option.goto;
    },
    gotoLink() {
        return this.option.goto_link;
    },
    */
  },

  methods: {
    localClickHandler() {
      // The "selected" property in not part of our option model schema,
      // so we need to use Vue.set to initialize the property if it does not already exist
      //this.$set(this.option, "selected", this.selected);

      this.$emit("click", this.option);
    },
  },
}
</script>

<style></style>
