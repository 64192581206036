import { Model } from "@vuex-orm/core";

export default class ActiveRecord extends Model {
  //Generate UUIDs
  static uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  //Generates sample data for given model
  //Calls model's dummyData() method which returns one row of data
  //fk_val = foreign key *value* for given model
  //count = number of rows to generate
  //order = whether data contains 'order' field that needs to incrimented based on existing records

  static seed(fk_val, count = 1, order = true) {
    if (order === true) {
      var max = this.findLast(this.getPeerRecords(this.foreignKey, fk_val));
    }

    let data = [];
    var i;
    for (i = 0; i < count; i++) {
      let row = this.dummyData(fk_val);
      if (order === true) row.order = max + i + 1;
      data.push(row);
    }
    return data;
  }

  //dummyData returns set of default data for model (needs to be implemented in each model)
  //NOTE: We can still rely on the model's defaults for most values, but usually want to assign a parent_id that isn't null 
  static dummyData() {
    throw new Error("dummyData() method missing from model");
  }


  //Re-indexes order fields for all peer children (e.g. step) in the current state
  //Accepts a single model instance record (single row)

  static reorder(record, fkey=null) {
    fkey = fkey || this.foreignKey;
    let fk_val = record[fkey];

    //Get all records that have the same parent, ordered by order field
    var collection = this.getPeerRecords(fkey, fk_val);

    //Re-index the options list so that new order field values are sequential
    var reorderedCollection = this.reIndexList(collection);

    //Re-save options with updated order field values
    this.update({ data: reorderedCollection });
  }

  //This returns all 'peer' records for the given model (e.g. all options with same step_id)

  static getPeerRecords(fkey, fkval) {
    var list = this.query()
      .where(fkey, fkval)
      .orderBy("order", "asc")
      .get();
    return list;
  }

  //This re-indexes 'order' field for each row to match the current existing array order
  //Use this to update model order field values after Vue Draggable re-order
  static reIndexList(items) {
    var i;

    for (i = 0; i < items.length; i++) {
      items[i]["order"] = i + 1;
    }
    return items;
  }

  // This is basically just a wrapper to Vuex-ORM's native insert() method
  // that returns the newly inserted model instead of the entire entities array

  static async insertNew(obj) {
    var entities = await this.insert(obj);

    //this.entity is a required static model property that returns a string (e.g. option, step)
    var collection = entities[this.entity];

    //get the first (most recent) model
    var model = collection[0];

    return model;
  }

  // Find highest order field value in the collection
  static findLast(array) {
    let max = 0;

    if (array.length > 0) {
      max = array[0].order;

      for (let i = 1; i < array.length; i++) {
        let v = array[i].order;
        max = v > max ? v : max;
      }
    }

    return max;
  }

  /*
  //Generate new primary keys for an array of model records (e.g. users )
  static generatePrimaryKeys(records, primary_key_field) {
    records.forEach((record) => {
      record[primary_key_field] = this.uuidv4();
    }, this);

    return records;
  }

  //Replace foreign keys for an array of records
  static updateForeignKeys(records, foreign_key_field, foreign_key_value) {
    records.forEach((record) => {
      record[foreign_key_field] = foreign_key_value;
    }, this);

    return records;
  }
  */
}
