import ActiveRecord from "@/models/ActiveRecord";

export default class Response extends ActiveRecord {
  // entity is a required property for all models.
  static entity = "response";

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      id: this.uid(() => this.uuidv4()),
      survey_id: this.attr(null),
      step_id: this.attr(null),
      visitor_id: this.attr(null),
      value: this.attr(null),
    };
  }
}
